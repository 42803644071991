.floating-button {
    position: fixed; /* Fixed position to stay in place during scrolling */
    left: 50%;
    top: 18.5%;
    transform: translate(-50%, -50%); /* Center the button */
    background-color: green; /* Green background */
    color: rgb(255, 255, 255); /* White text */
    border: none;
    padding: 15px 30px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 30px;
    opacity: 50%;
    animation: pulse-animation 2s infinite; /* Animation */
  }
  
  /* Keyframes for the pulse animation */
  @keyframes pulse-animation {
    0% {
      transform: translate(-50%, -50%) scale(1);
    }
    50% {
      transform: translate(-50%, -50%) scale(1.1);
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
    }
  }
  