.burger-menu {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 25px;
    padding-right: 25px;
      
  }
  
  .burger-line {
    background-color: #049d39b9;
    height: 3px;
    width: 100%;
    transition: all 0.3s linear;
  }
  
  .burger-menu.open .burger-line:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }
  
  .burger-menu.open .burger-line:nth-child(2) {
    opacity: 0;
  }
  
  .burger-menu.open .burger-line:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
  }
  