.App_image_image_test {
  text-align: center;
  background-color: #222529; /* Fallback color */
  background-image: url('/public/turf.png'); /* Path to the image */
  background-size: cover; /* Cover the entire area */
  background-position: center; /* Center the image */
  color: #ecf0f1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}


.App {
  text-align: center;
  color: #ecf0f1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  overflow: hidden; /* Ensure no scrolling */
}

.background-video {
  position: fixed; /* Position fixed to cover the whole viewport */
  top: 0;
  left: 0;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  object-fit: cover; /* Cover the entire area without losing aspect ratio */
  z-index: -1; /* Ensure it stays behind other content */
}

/* Your existing form and other styles */


  
  form {
    margin-top: 20px;
    background-color: #000000;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 500px; /* Max width for larger screens */
    opacity: 0.8;
  }
  
  fieldset {
    border: 2px solid #ffffff;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 15px;
  }
  
  legend {
    color: #ffffff;
    font-weight: bold;
  }
  
  label {
    display: block;
    margin: 10px 0;
    cursor: pointer;
  }
  
  input[type="radio"] {
    margin-right: 5px;
  }
  
  button {
    background-color: #287631;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
  }
  
  button:hover {
    background-color: #52ae66;
  }
  
  /* Responsive styles */
  @media (max-width: 600px) {
    .App {
      padding: 20px;
    }
  
    form {
      width: 100%;
      padding: 15px;
    }
  
    button {
      padding: 10px;
    }
  }
  

  .question {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 0.5s, transform 0.5s;
  }
  
  .question.visible {
    opacity: 1;
    transform: translateX(0);
  }
  
 