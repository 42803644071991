 /* Existing styles */
 .App-header {
  background-color: #000000; /* A distinct color for the header */
  padding: 10px; /* Reduced padding for smaller size */
  width: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  color: white;
  font-size: 16px; /* Smaller font size */
  position: fixed; /* Fix header to the top */
  top: 0;
  left: 0;
  z-index: 1000; /* Ensure it's above other elements */
  display: flex;
  justify-content: space-between; /* Spread items across the header */
  align-items: center;
}

@media (max-width: 600px) {
  .App-header {
    font-size: 14px; /* Even smaller font size for mobile screens */
  }
}

header {
  /* Other header styles */
  display: flex;
  align-items: center;
}


.header-icon {
  margin-right: 5px; /* Adjust the spacing as needed */
  vertical-align: middle; /* Aligns the icon vertically with adjacent elements */
  height: 50px; /* Adjust the size as needed */
  animation: float 3s ease-in-out infinite; /* Apply the floating animation */
}



.header-icon1 {
  margin-right: 5px; /* Adjust the spacing as needed */
  vertical-align: middle; /* Aligns the icon vertically with adjacent elements */
  height: 50px; /* Adjust the size as needed */
  animation: float 3.5s ease-in-out infinite; /* Apply the floating animation */
}



@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}






.header-logo {
  height: 50px; /* Or whatever size fits your design */
  margin-right: 10px; /* Adjust spacing as needed */
}
.burger-menu {
  margin-right: 10px; /* Adjust as needed */
}




.Sub-header {
  width: 100%; /* Span the full width of its container */
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the color and transparency to your liking */
  color: white; /* Ensures the text within is legible against the darkened veil */
  padding: 10px; /* Provide some space within */
  position: fixed; /* It shall hover directly beneath the primary header */
  top: 60px; /* Adjust this value so it sits right below the main header */
  left: 0;
  text-align: center; /* Center the text or contents within */
  z-index: 999; /* High enough to float above the page content, but below the main header */
}