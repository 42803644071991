.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #000000;
    padding: 10px;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.3);
    z-index: 500; /* Adjust as needed to not interfere with other elements */
  }
  
  .ai-button {
    position: relative;
    right: 20px;
    background-color: #000000;
    color: #16c013;
    border: none;
    padding: 5px;
    border-radius: 0%;
    font-size: 20px;
    cursor: pointer;
    animation: glow 1.5s infinite alternate;
  }
  
  @keyframes glow {
    to {
      box-shadow: 0 0 20px #16c013;
    }
  }
  
  .message-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .messages {
    max-height: 150px; /* Adjust as needed */
    overflow-y: auto;
    width: 100%;
    padding: 10px;
  }
  
  .messages p {
    background: #34495e;
    color: white;
    padding: 5px;
    border-radius: 5px;
    margin: 5px 0;
  }
  
  form {
    display: flex;
    width: 100%;
  }
  
  form input {
    flex: 1;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #3195e6;
    margin-right: 5px;
  }
  
  form button {
    padding: 10px;
    background-color: #16a085;
    color: white;
    border: none;
    border-radius: 5px;
  }
  