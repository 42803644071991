.side-menu {
    position: fixed;
    left: -260px;
    top: 0;
    width: 250px;
    height: 100%;
    background: #000000;
    box-shadow: 10px 0 10px rgba(0, 0, 0, 0.5);
    transition: left 0.3s ease-in;
    padding: 10px;
    /*here is the additional styling to adjust for the cslb lic. info = class .lic-info */


  }
  
  .side-menu.open {
    left: 0;
  }
  
  .side-menu-logo {
    width: 100px;
    margin: 10px 0;
  }
  
  .side-menu-list {
    list-style: none;
    padding: 0;
  }
  
  .side-menu-list li {
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
  }
  


  .header-icon1 {
    width: 50px;
    margin: 10px 0;
    margin-right: 5px; /* Adjust the spacing as needed */
    vertical-align: middle; /* Aligns the icon vertically with adjacent elements */
    height: 100px; /* Adjust the size as needed */
    animation: float 3.5s ease-in-out infinite; /* Apply the floating animation */
  }
  
  p {
    font-style: italic;
  }
  
  
  @keyframes float {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0px);
    }
  }


/*this is the section for the lic info */

.lic-info {
position: absolute;
bottom: 50px;
width: 100%;
text-align: center;


  }

.lic-info a {

  color: green;
  text-decoration: none;

}


  .lic-info a:hover {
    text-decoration: none;
    color: white;
  }